import React, { useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { useaddAgentStyle } from "@/static/stylesheets/molecules/addagent";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import LogoPrev from "@/static/icons/ic_previous.png";
import { FreePlan } from "@/components/molecules/FreePlan";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppService } from "@/service/AllApiData.service";
import { Alert, AlertError } from "@/alert/Alert";
import { removeEmptyKeys } from "@/helper/util";
import Loading from "@/components/LoadingCom/Loading";

const AddNewPlan = () => {
  const classes = useaddAgentStyle();
  const navigate = useNavigate();
  const { id } = useParams();
  const [AllBazaar, setAllBazaar] = React.useState<any>([]);
  const [consts, setCounts] = React.useState<any>(1);
  const [previewData, setPreviewData] = React.useState({
    featureData: [],
    state: [],
    plantype: "FREE",
    branches: 0,
  });
  const [localStoragedata, setlocalStorageData] = useState<any>(
    JSON.parse(localStorage.getItem("setplandata") || "{}") || ""
  );
  const [localStoragedatas, setlocalStorageDatas] = useState<any>(
    JSON.parse(localStorage.getItem("previewData") || "{}") || ""
  );
  const [feature, setFeature] = React.useState<any>(
    localStoragedata?.plan_features || []
  );
  const [tally, settally] = React.useState(
    localStoragedata?.plan_tally || false
  );
  const [isLocked, setIsLocked] = useState(false);
  const superAdmin = true;
  const agency = JSON.parse(localStorage.getItem("agency") || "{}");
  const [bazaarWiseData, setBazaarWiseData] = React.useState<any>(
    localStoragedata?.bazaar_assigned_data || []
  );
  const [initialLoading, setInitialLoading] = useState(true);
  const [bazaarData, setBazaarData] = useState<any[]>([]);
  const allowedBazaars = agency?.agent_bazaar_data?.map(
    (item: any, index: number) => ({
      label: item.bazaar_name,
      value: item.id,
    })
  );
  const allowedStates = agency?.agent_assigned_state_names?.map(
    (item: any, index: number) => ({
      label: item.state,
      value: item.id,
    })
  );
  const allowedDistrict = agency?.agent_assigned_district_names?.map(
    (item: any, index: number) => ({
      label: item.district,
      value: item.id,
    })
  );
  const allowedCity = agency?.agent_assigned_city_names?.map(
    (item: any, index: number) => ({
      label: item.city,
      value: item.id,
    })
  );

  React.useEffect(() => {
    if (consts) {
      getAllBazaar();
    }
  }, [consts]);

  const getPlanById = async () => {
    const responseJson = await AppService.getPlanById(id);
    console.log("logger123....", responseJson, {
      ...responseJson.data,
      bazaar: responseJson.data.bazaar.map((item: any) => item.id),
      city: responseJson.data.bazaar_assigned_data[0].city,
      district: responseJson.data.bazaar_assigned_data[0].district,
      plan_features: responseJson.data.plan_features.map(
        (item: any) => item.id
      ),
      state: responseJson.data.bazaar_assigned_data[0].state,
    });
    setFeature([
      ...responseJson.data.plan_features.map((item: any) => item.id),
    ]);
    setIsLocked(responseJson.data.is_locked);
    setBazaarWiseData(responseJson.data.bazaar_assigned_data);
    setBazaarData(responseJson.data.bazaar_assigned_data);
    await setValues({
      ...responseJson.data,
      bazaar: responseJson.data.bazaar.map((item: any) => item.id),
      city: responseJson.data.bazaar_assigned_data[0].city,
      district: responseJson.data.bazaar_assigned_data[0].district,
      plan_features: responseJson.data.plan_features.map(
        (item: any) => item.id
      ),
      state: responseJson.data.bazaar_assigned_data[0].state,
    });
    setInitialLoading(false);
  };

  React.useEffect(() => {
    if (id && Object.keys(localStoragedata).length===0) {
      getPlanById();
    } else {
      setInitialLoading(false);
    }
  }, [id]);

  const getAllBazaar = async () => {
    const responseJson = await AppService.getAllBazaars();
    if (responseJson.status == 200) {
      // let data = responseJson?.data?.map((item:any,index:number)=>({
      //   label: item?.bazaar_name,
      //   value: item?.id
      // }))

      // const responseJson = await AppService.getAllBazarList();
      console.log("logger123....", responseJson, "responseJson");
      let tempBazaar = await responseJson.data
        .map((row: any) => {
          const alloweIds = allowedBazaars?.map((item: any) => item.value);
          if (alloweIds?.includes(row.id) || superAdmin) {
            return {
              label: row.bazaar_name,
              value: row.id,
              ...row,
            };
          }
          return null;
        })
        .filter((item: any) => item !== null);

      setAllBazaar([...tempBazaar]);
    }
  };

  const [initialValues, setinitialValues] = useState<any>({
    plan_choice: localStoragedata?.plan_choice || "FREE",
    plan_name: localStoragedata?.plan_name || "",
    start_date: localStoragedata?.start_date || null,
    start_time: localStoragedata?.start_time || "",
    end_date: localStoragedata?.end_date || null,
    end_time: localStoragedata?.end_time || "",
    plan_tally: localStoragedata?.plan_tally || false,
    amount: localStoragedata?.amount || 0,
    branches: localStoragedata?.branches || 0,
    user_per_branch: localStoragedata?.user_per_branch || 0,
    bazaar: localStoragedata?.bazaar || [],
    plan_features: localStoragedata?.plan_features || [],
    plan_active: true,
    plan_periods_in_days: localStoragedata?.plan_periods_in_days || "",

    whatsapp_notification: localStoragedata?.whatsapp_notification || false,
    whatsapp_notification_limit:
      localStoragedata?.whatsapp_notification_limit || 0,
    sms_notification: localStoragedata?.sms_notification || false,
    sms_notification_limit: localStoragedata?.sms_notification_limit || 0,
    agents_limit: localStoragedata?.agents_limit || 0,
    products_limit: localStoragedata?.products_limit || 0,
    retailers_limit: localStoragedata?.retailers_limit || 0,
    users_limit: localStoragedata?.users_limit || 0,
    orders_limit: localStoragedata?.orders_limit || 0,
  });

  const validationSchema = Yup.object().shape({
    plan_name: Yup.string()
      .min(2, "Too Short!")
      .max(40, "Too Long!")
      .required("Plan name is required"),
    // branches: Yup.number().min(1, "Branch should be atleast 1").required("Branch is required"),
    // start_date: Yup.date().required("Start date is required"),
    // start_time: Yup.string().required("Start time is required"),
    // bazaar: Yup.array().min(1, "Select atleast 1 Bazaar").required("Bazaar is required"),
    // state: Yup.number().required("State is required"),
    // district: Yup.array().min(1, "Select atleast 1 District").required("District is required"),
    // city: Yup.array().min(1, "Select atleast 1 City").required("City is required"),
    // plan_features: Yup.array().min(1, "Select atleast 1 Feature").required("Features is required"),
    plan_periods_in_days: Yup.number()
      .min(1, "Plan Period should be atleast 1")
      .required("Plan Period is required"),
    amount: Yup.number().when("plan_choice", {
      is: "PAID",
      then: (schema) =>
        schema
          .min(1, "Amout should be atleast 1")
          .required("Amount is required"),
    }),
    // whatsapp_notification_limit: Yup.number().when("whatsapp_notification", {
    //   is: true,
    //   then: (schema) => schema.min(1, "Number of Whatsapp Notification should be atleast 1").required("Number of Whatsapp Notification is required")
    // }),
    // sms_notification_limit: Yup.number().when("sms_notification", {
    //   is: true,
    //   then: (schema) => schema.min(1, "Number of SMS Notification should be atleast 1").required("Number of SMS Notification is required")
    // }),
    // agents_limit: Yup.number().required("Number of Agents is required"),
    // products_limit: Yup.number().required("Number of Products is required"),
    // retailers_limit: Yup.number().required("Number of Retailers is required"),
    // users_limit: Yup.number().required("Number of Users is required")
  });

  const onSubmit = async (values: any) => {
    let error = false;
    if (values.bazaar.length === 0) {
      setErrors({ bazaar: "Select atleast 1 Bazaar" });
      return;
    }
    bazaarWiseData.map((item: any) => {
      if (!item.state.length || !item.district.length || !item.city.length) {
        error = true;
      }
    });

    if (error) {
      AlertError("Please fill all reaquired field *");
      return;
    }

    if (values.start_date && values.end_date) {
      if (
        new Date(values.end_date).getTime() <=
        new Date(values.start_date).getTime()
      ) {
        AlertError("End Date should be greater than start date");
        return;
      }
    }

    // return;
    try {
      console.log(
        new Date(values.start_date).getTime(),
        values.start_date,
        "values.start_time"
      );
      values.plan_features = feature;
      values.plan_tally = tally;
      delete values.agent_assigned_state;
      let payload = Object.assign({}, values);
      payload.bazaar = payload.bazaar;
      payload.bazaar_assigned_data = bazaarWiseData;

      console.log("Plans Payload ===> ", payload);
      // return;
      // payload = payload ? removeEmptyKeys(payload) : payload;
      // if(values.start_date &&  values.end_date){
      // if(new Date(values.start_date).getTime() < new Date(values.end_date).getTime()){
      if (id) {
        await AppService.updatePlan(id, payload);
        await Alert("Plan Updated Successfully");
        localStorage.removeItem("setplandata");
        // await setValues({
        //   plan_choice: "FREE",
        //   plan_name: "",
        //   start_date: "",
        //   start_time: "",
        //   end_date: "",
        //   end_time: "",
        //   plan_tally: false,
        //   plan_active: true,
        //   amount: 0,
        //   branches: 0,
        //   user_per_branch: 0,
        //   bazaar: [],
        //   // state: [],
        //   // city: [],
        //   // district: [],
        //   plan_features: [],
        //   plan_periods_in_days: "",

        //   whatsapp_notification: false,
        //   whatsapp_notification_limit: "",
        //   sms_notification: false,
        //   sms_notification_limit: "",
        //   agents_limit: "",
        //   products_limit: "",
        //   retailers_limit: "",
        //   users_limit: ""
        // });

        setBazaarWiseData([]);
      } else {
        await AppService.addNewPlan(payload);
        await Alert("Plan Added Successfully");
        localStorage.removeItem("setplandata");
        await setValues({
          plan_choice: "FREE",
          plan_name: "",
          start_date: "",
          start_time: "",
          end_date: "",
          end_time: "",
          plan_tally: false,
          plan_active: true,
          amount: 0,
          branches: 0,
          user_per_branch: 0,
          bazaar: [],
          // state: [],
          // city: [],
          // district: [],
          plan_features: [],
          plan_periods_in_days: "",

          whatsapp_notification: false,
          whatsapp_notification_limit: 0,
          sms_notification: false,
          sms_notification_limit: 0,
          agents_limit: 0,
          products_limit: 0,
          retailers_limit: 0,
          users_limit: 0,
        });

        // setBazaarWiseData([]);
      }
      navigate("/plans");
      // }
      // else{
      //   AlertError('please enter a starting date bigger');
      // }
      // }
      // else{
      //   AlertError('please enter date');

      // }
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.type) {
        let message = error.response.data.type + "\n";
        error.response.data.errors.map((row: any) => {
          message += row.attr + " : " + row.detail + "\n";
        });
        AlertError(message);
      }
    }
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    setValues,
    setFieldValue,
    handleBlur,
    touched,
    isValid,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  console.log(localStoragedatas, "initialValues");
  return (
    <>
      <DashboardLayout>
        {initialLoading ? (
          <Loading />
        ) : (
          <div className={classes.root}>
            <div className="container">
              <div className="flex gap-5 items-center justify-between mb-[20px]">
                <div className="flex gap-5 items-center">
                  <div>
                    <img
                      className="w-[10px] cursor-pointer"
                      src={LogoPrev}
                      alt={"Logo"}
                      onClick={() => navigate("/plans")}
                    />
                  </div>
                  <div className="headTitle">
                    {id ? "Edit Plan" : "Add New Plan"}
                  </div>
                </div>
              </div>

              <div className="radio-actionButton">
                <div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="radio-buttons"
                      name="controlled-radio-buttons"
                      value={values.plan_choice}
                      onChange={() => {
                        setFieldValue("plan_choice", "FREE");
                        setPreviewData((prev: any) => ({
                          ...prev,
                          plantype: "FREE",
                        }));
                      }}
                    >
                      <FormControlLabel
                        value="FREE"
                        control={<Radio />}
                        checked={values.plan_choice === "FREE"}
                        label={
                          <div className="flex gap-4 items-center planTitle">
                            Free
                          </div>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="radio-buttons"
                      name="controlled-radio-buttons"
                      value={values.plan_choice}
                      onChange={() => {
                        setFieldValue("plan_choice", "PAID");
                      }}
                    >
                      <FormControlLabel
                        value="PAID"
                        control={<Radio />}
                        checked={values.plan_choice === "PAID"}
                        label={
                          <div className="flex gap-4 items-center planTitle">
                            Paid
                          </div>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div>
                <FreePlan
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  id={id ? id : ""}
                  AllBazaar={AllBazaar}
                  handleSubmit={handleSubmit}
                  feature={feature}
                  setFeature={setFeature}
                  settally={settally}
                  tally={tally}
                  setPreviewData={setPreviewData}
                  previewData={previewData}
                  touched={touched}
                  isValid={isValid}
                  localStoragedata={localStoragedata}
                  bazaarWiseData={bazaarWiseData}
                  setBazaarWiseData={setBazaarWiseData}
                  isLocked={isLocked}
                  handleBlur={handleBlur}
                  bazaarData={bazaarData}
                />
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default AddNewPlan;
