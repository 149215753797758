import React, { useEffect, useState } from "react";
import { GridOptionButton } from "@/components/atoms/Button";
import { useProductListStyles } from "@/static/stylesheets/molecules";
import LogoContract from "@/static/icons/uploader-frame.png";
import { AppService } from "@/service/AllApiData.service";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Pagination, Typography } from "@mui/material";
import { AddButton } from "@/components/atoms/Button";
import { SearchField } from "@/components/atoms/SearchField";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, AlertError } from "@/alert/Alert";
import complete from "@/static/icons/complete.svg";
import fill from "@/static/icons/fill.svg";
import calendar from "@/static/icons/calendar.svg";
import deleteagent from "@/static/icons/delete-agent.svg";
import closeicon from "@/static/images/Vector (10).png"
import { readFileAsBase64 } from "@/helper/base64";
import UploaderFrame from "@/static/icons/uploader-frame.png";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import Pinimage from "@/static/images/Vector (9).png";
import moment from "moment";
// import Tree from "react-d3-tree";
import "../../../static/stylesheets/custom-tree.css"
import ExpandMore from "@mui/icons-material/ExpandMore";

const GroupCategoryList = (props: any) => {
  const classes = useProductListStyles();
  const { id } = useParams()
  const navigate = useNavigate()
  const [searchK, setSearchK] = useState("");
  const [AllfilterAllGroupCategory, setAllfilterAllGroupcategory] = useState<any>([])
  const [AllGroupCategory, setAllGroupCategory] = useState<any>([]);
  const [AllCategory, setAllCategory] = useState<any>([]);
  const [AllSubCategory, setAllSubCategory] = useState<any>([]);
  const IdParams = Number(id)
  const [totalCount, SetTotalCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryChartList, setCategoryChartList] = useState<any>([]);
  const [filteredCategoryChartList, setFilteredCategoryChartList] = useState<any>([]);


  // const handlePageChange = (event: any, value: any) => {
  //   setCurrentPage(value);
  //   getAllListsM(value);
  // };

  // const getAllListsM = async (page: any) => {
  //   try {
  //     const params = {
  //       bazaar: IdParams,
  //       page: page ? page : 1
  //     }
  //     const responseJson = await AppService.listSubCateByCat(params)
  //     setAllfilterAllGroupcategory(responseJson.data.results)
  //     SetTotalCount(responseJson.data.count)
  //     responseJson.data.results.forEach((item: any) => {
  //       Promise.all([fetchCategoryDetail(item.category), fetchParentCategoryDetails(item.category_group)])
  //     })
  //   } catch (err) {
  //     console.log("Error: ", err)
  //   }
  // }

  // const listSubcategorydata = async () => {
  //   try {
  //     const res = await AppService.listSubcategory()
  //     setAllSubCategory(res.data.results)
  //   } catch (err) {
  //     console.log("Error: ", err)
  //   }
  // }

  // useEffect(() => {
  //   listSubcategorydata()
  //   getAllListsM(1);
  // }, []);

  const generateCategoryListChart = async (): Promise<void> => {
    try {
      const response = await AppService.listGroupCateByBazaar({ bazaar: id });
      const list: any[] = await Promise.all(response.data.results.map(async (item: any) => {
        const childrenResponse = await AppService.listCateByParent({ bazaar: id, category_group: item.id });
        const childCategories: any[] = await Promise.all(childrenResponse.data.results.map(async (ele: any) => {
          const subCategoriesResponse = await AppService.listSubCateByCat({ bazaar: id, category_group: item.id, category: ele.id });
          return {
            name: ele.category_name,
            image: ele.category_ref_image,
            children: subCategoriesResponse.data.results.map((subItem: any) => ({
              name: subItem.subcategory_name,
              image: subItem.subcategory_ref_image,
              children: []
            }))
          };
        }));
        return {
          name: item.parent_category_name,
          image: item.parent_category_ref_image,
          children: childCategories
        };
      }));

      console.log("logger123...", list);
      setCategoryChartList(list)
      setFilteredCategoryChartList(list)
    } catch (err) {
      // Handle errors here
      console.error(err);
    }
  };


  useEffect(() => {
    console.log("Group Category", props.handleModalBackdrop)
    generateCategoryListChart();
  }, [props.handleModalBackdrop])

  const handleCallback = (e: any) => {
    setSearchK(e);
  }

  useEffect(() => {
    let filtered = categoryChartList.filter((item: any) => {
      console.log(searchK, item.name)
      return item.name.toLowerCase().includes(searchK.toLowerCase()) || item?.children?.filter((ele:any)=>ele.name.toLowerCase().includes(searchK.toLowerCase() || ele?.children?.filter((obj:any)=>obj.name.toLowerCase().includes(searchK.toLowerCase()))?.length ))?.length
    })
    setFilteredCategoryChartList([...filtered])
  }, [searchK])

  const fetchParentCategoryDetails = async (id: number) => {
    try {
      const res = await AppService.listGroupCategoryById(id)
      setAllGroupCategory((prev: any) => prev.find((item: any) => item.id === res.data.id) ? prev : prev.concat(res.data))
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  const fetchCategoryDetail = async (id: number) => {
    try {
      const response = await AppService.listMaincategory()
      setAllCategory(response.data.results)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  const dimensions = {
    width: 600, // specify your desired tree width
    height: 600 // specify your desired tree height
  };

  const translate = { x: dimensions.width / 2, y: dimensions.height / 2 }; // adjust x and y values as needed

  console.log(props?.handleModalBackdrop, "props?.handleModalBackdrop")
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={6}>
          <div className="leftContent">
            <form className="max-w-sm px-4">
              <div className="relative">
                <SearchField parentCallback={handleCallback} />
              </div>
            </form>
            {/* <div>
              <AddButton label="Add Bazaar" onClick={() => navigate('/newbazaars')} />
            </div> */}
          </div>
        </Grid>
      </Grid>
      <div style={{ marginTop: "30px" }}>

      {
  filteredCategoryChartList.map((item: any, index: number) => (
    <Accordion key={`parent-${index}`} style={{
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      marginBottom: "10px",
      pointerEvents: item.children.length > 0 ? "auto" : "none",
      opacity: item.children.length > 0 ? 1 : 0.6,
    }} >
      <AccordionSummary
        expandIcon={item.children.length ? <ExpandMore /> : null}
        aria-controls={`panel-${index}-content`}
        id={`panel-${index}-header`}
        
      >
        <div className="flex items-center gap-3">
          <img src={item.image} width={50} height={50} className="rounded-lg" alt={item.name} />
          <p className="mb-0">{item.name}</p>
        </div>
      </AccordionSummary>
      {item.children.length > 0 && (
        <AccordionDetails>
          {item.children.map((child: any, childIndex: number) => (
            <Accordion key={`child-${childIndex}`} style={{pointerEvents: child.children.length > 0 ? "auto" : "none",
              opacity: child.children.length > 0 ? 1 : 0.6,}}>
              <AccordionSummary
                expandIcon={child.children.length ? <ExpandMore /> : null}
                aria-controls={`panel-${index}-${childIndex}-content`}
                id={`panel-${index}-${childIndex}-header`}
              >
                <div className="flex items-center gap-3">
                  <img src={child.image} width={50} height={50} className="rounded-lg" alt={child.name} />
                  <p className="mb-0">{child.name}</p>
                </div>
              </AccordionSummary>
              {child.children.length > 0 && (
                <AccordionDetails>
                  {child.children.map((ele: any, subChildIndex: number) => (
                    <Accordion  expanded={child.children.length > 0} key={`subChild-${subChildIndex}`} style={{pointerEvents: child.children.length > 0 ? "auto" : "none",
                      opacity: child.children.length > 0 ? 1 : 0.6,}}>
                      <AccordionSummary
                        expandIcon={ele.children.length ? <ExpandMore /> : null}
                        aria-controls={`panel-${index}-${childIndex}-${subChildIndex}-content`}
                        id={`panel-${index}-${childIndex}-${subChildIndex}-header`}
                      >
                        <div className="flex items-center gap-3">
                          <img src={ele.image} width={50} height={50} className="rounded-lg" alt={ele.name} />
                          <p className="mb-0">{ele.name}</p>
                        </div>
                      </AccordionSummary>
                    </Accordion>
                  ))}
                </AccordionDetails>
              )}
            </Accordion>
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  ))
}



        {/* <table style={{ marginBottom: "50px", width: "100%" }}>
          <tr>
            <th style={{ padding: "7px 15px", paddingLeft: 0 }}>Group Category</th>
            <th style={{ padding: "7px 15px", textAlign: "center" }}>Category</th>
            <th style={{ padding: "7px 15px", textAlign: "end" }}>Sub-Category</th>
          </tr>
          {props?.handleModalBackdrop?.map((elm: any,index:any) => {
            return (
              <tr key={index}>
                <td style={{ paddingRight: "15px", minWidth: "135px" }}>
                  <div className="bg-[#e5f7ff] flex justify-center rounded-md p-[5px] w-[200px]">
                    <p className="text-[#15abff]" style={{ fontSize: "12px"}}>
                      {elm?.parent_category_name}
                    </p>
                  </div>
                </td>
                <td style={{ padding: "7px 15px", textAlign: "center" }}>
                  <div className="bg-[#e5f7ff] flex justify-center rounded-md p-[5px] w-[200px]" style={{ margin: "0 auto" }}>
                    <p className="text-[#15abff]" style={{ fontSize: "12px" }}>
                      {AllCategory?.length && AllCategory?.map((x:any)=> elm?.id == x?.category_group && x?.category_name)}
                    </p>
                  </div>
                </td>
                <td style={{ padding: "7px 15px", textAlign: "end" }}>
                  <div className="bg-[#e5f7ff] flex justify-center rounded-md p-[5px] w-[250px]" style={{ margin: "0 auto", marginRight: 0 }}>
                    <p className="text-[#15abff]" style={{ fontSize: "12px" }}>
                      {AllCategory?.length && AllCategory?.map((x:any)=> elm?.id == x?.category_group && AllSubCategory.map((y:any)=> x?.id == y?.category && y?.subcategory_name))}
                    </p>
                  </div>
                </td>
              </tr>
            )
          })}
        </table> */}
      </div>
    </div>
  );
};

export { GroupCategoryList };