import React, { useEffect, useState } from "react";
import { useElectronicsBazaarStyles } from "@/static/stylesheets/screens";
import { Grid } from "@mui/material";
import { FeaturesTab } from "@/components/organisms/FeaturesTab";
import { FreePlanview, PaidPlanview, Planview } from "../PlanCard";
import { AppService } from "@/service/AllApiData.service";

interface Iprops {
  initialValues: any;
  setActiveStep?: any;
  selectedPlan?: any;
  setSelectedPlan?: any;
}

const PlanTab = (props: Iprops) => {
  const classes = useElectronicsBazaarStyles();
  const [allPlans, setAllPlans] = useState<any>([]);
  const [TotalPlanscount, setTotalPlanscount] = useState<any>([]);
  const [Freeplans, setFreeplans] = useState<any>([]);
  const [PaidPlans, setPaidPlans] = useState<any>([]);

  const { initialValues } = props;

  React.useEffect(() => {
    PlansAllApi();
  }, []);

  const PlansAllApi = async () => {
    try {
      const res = await AppService.getPlans({
        bazaar: initialValues.wholeseller_bazaar.join(","),
        state: initialValues.wholeseller_bazaar_data[0].bazaar_state.join(","),
        district: initialValues.wholeseller_bazaar_data[0].bazaar_district.join(","),
        city: initialValues.wholeseller_bazaar_data[0].bazaar_city.join(","),
      });
      if (initialValues.wholeseller_status == "PENDING") {
        res.data.results.map((x: any) => {
          if (initialValues?.wholeseller_plan == x.id) {
            allPlans.push(x);
          }
        });
        setAllPlans([...allPlans]);
      } else {
        setAllPlans(res.data.results);
      }
      setTotalPlanscount(res.data.count);
    } catch (error: any) {
      console.log("err", error.message);
    }
  };
  useEffect(() => {
    if (allPlans?.length > 0) {
      const FreeplansData = allPlans?.filter((plan: any) =>
        plan?.plan_choice?.includes("FREE")
      );
      setFreeplans(FreeplansData);
    }
  }, [allPlans]);

  useEffect(() => {
    if (allPlans?.length > 0) {
      const Paidplansdata = allPlans?.filter((plan: any) =>
        plan?.plan_choice?.includes("PAID")
      );
      setPaidPlans(Paidplansdata);
    }
  }, [allPlans]);

  return (
    <div className={classes.root}>
      {/* <Grid container spacing={2} className="p-0 m-0">
        <Grid item xs={12} className="p-0 m-0"> */}
      <div className="lg:ml-6">
        <FeaturesTab
          items={[
            {
              label: `All Plans (${TotalPlanscount})`,
              content: (
                <Planview
                  selectedPlan={props.selectedPlan}
                  setSelectedPlan={props.setSelectedPlan}
                  allPlans={allPlans}
                />
              ),
            },
            {
              label: `Free (${Freeplans?.length})`,
              content: (
                <FreePlanview
                  selectedPlan={props.selectedPlan}
                  setSelectedPlan={props.setSelectedPlan}
                  Freeplans={Freeplans}
                />
              ),
            },
            {
              label: `Paid (${PaidPlans?.length})`,
              content: (
                <PaidPlanview
                  selectedPlan={props.selectedPlan}
                  setSelectedPlan={props.setSelectedPlan}
                  PaidPlans={PaidPlans}
                />
              ),
            },
          ]}
        />
      </div>
      {/* </Grid>
      </Grid> */}
    </div>
  );
};

export default PlanTab;
